import { DateTime } from "luxon";

function isWithinWorkingHours(office, date) {
  if (date.weekday <= 5) {
    return (
      date.hour >= office.workingHoursMonFri.start &&
      date.hour < office.workingHoursMonFri.end
    );
  }
  return (
    date.hour >= office.workingHoursSaturday.start &&
    date.hour < office.workingHoursSaturday.end
  );
}

export function calculateRental(
  from,
  to,
  segment,
  car,
  deliveryOffice,
  returnOffice,
  options = [],
  insurance = false
) {
  const fromDate = DateTime.fromMillis(from);
  const toDate = DateTime.fromMillis(to);
  const numDays = Math.ceil(toDate.diff(fromDate).as("days"));
  let dayPrice = segment.dayPrices[0];
  if (numDays > 1) {
    dayPrice = segment.dayPrices[1];
  }
  if (numDays > 2) {
    dayPrice = segment.dayPrices[2];
  }
  if (numDays > 7) {
    dayPrice = segment.dayPrices[3];
  }
  if (numDays > 14) {
    dayPrice = segment.dayPrices[4];
  }
  if (numDays > 30) {
    dayPrice = segment.dayPrices[5];
  }
  let periodPrice = dayPrice * numDays;
  let optionsSum = 0;
  options.forEach((option) => {
    optionsSum += option.multiplyByDays ? option.price * numDays : option.price;
  });
  const deposit = segment.deposit;
  const depositCost = insurance ? 0 : deposit;
  const insuranceDayPrice = segment.insurance;
  const insurancePeriodPrice = segment.insurance * numDays;
  const insuranceTotalCost = insurance ? insurancePeriodPrice : 0;
  const deliveryCost = deliveryOffice ? deliveryOffice.deliveryFee : 0;
  const returnCost = returnOffice ? returnOffice.returnFee : 0;
  const deliveryOutsideWorkingHoursCost = deliveryOffice
    ? isWithinWorkingHours(deliveryOffice, fromDate)
      ? 0
      : deliveryOffice.deliveryFeeOutsideWorkingHours
    : 0;
  const returnOutsideWorkingHoursCost = returnOffice
    ? isWithinWorkingHours(returnOffice, toDate)
      ? 0
      : returnOffice.deliveryFeeOutsideWorkingHours
    : 0;
  let carPreparationFee = 0;
  if (numDays <= 3) {
    carPreparationFee = 89;
  }
  if (numDays <= 2) {
    carPreparationFee = 158;
  }
  const feesSum =
    deliveryCost +
    returnCost +
    deliveryOutsideWorkingHoursCost +
    returnOutsideWorkingHoursCost +
    carPreparationFee;
  const sum =
    periodPrice + insuranceTotalCost + depositCost + optionsSum + feesSum;

  let allPositions = [];
  allPositions.push({
    name: `${numDays} ${numDays === 1 ? "dzień" : "dni"} x ${dayPrice} zł/doba`,
    value: periodPrice,
  });
  if (insuranceTotalCost) {
    allPositions.push({
      name: "Ubezpieczenie",
      value: `${insuranceTotalCost} zł`,
    });
  }
  if (depositCost) {
    allPositions.push({
      name: "Kaucja",
      value: `${depositCost} zł`,
    });
  }
  let optionsPositions = options.map((option) => ({
    name: option.name,
    value: `${
      option.multiplyByDays ? option.price * numDays : option.price
    } zł`,
  }));
  allPositions.push(...optionsPositions);
  let feesPositions = [];
  if (deliveryCost) {
    feesPositions.push({
      name: `Podstawienie pod wskazany adres`,
      value: `${deliveryCost} zł`,
    });
  }
  if (returnCost) {
    feesPositions.push({
      name: `Zwrot pod wskazanym adresem`,
      value: `${returnCost} zł`,
    });
  }
  if (deliveryOutsideWorkingHoursCost) {
    feesPositions.push({
      name: `Odbiór poza godzinami pracy wypożyczalni`,
      value: `${deliveryOutsideWorkingHoursCost} zł`,
    });
  }
  if (returnOutsideWorkingHoursCost) {
    feesPositions.push({
      name: `Zwrot poza godzinami pracy wypożyczalni`,
      value: `${returnOutsideWorkingHoursCost} zł`,
    });
  }
  if (carPreparationFee) {
    feesPositions.push({
      name: `Opłata za przygotowanie samochodu`,
      value: `${carPreparationFee} zł`,
    });
  }
  allPositions.push(...feesPositions);

  let res = {
    fromDate,
    toDate,
    numDays,
    dayPrice,
    periodPrice,
    insuranceDayPrice,
    insurancePeriodPrice,
    insuranceTotalCost,
    deposit,
    depositCost,
    optionsSum,
    deliveryCost,
    returnCost,
    deliveryOutsideWorkingHoursCost,
    returnOutsideWorkingHoursCost,
    feesSum,
    allPositions,
    optionsPositions,
    feesPositions,
    sum,
  };
  return res;
}
