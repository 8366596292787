import React from "react";
import styled from "styled-components";
import DialogContent2 from "@material-ui/core/DialogContent";

export const DialogContent = styled(DialogContent2)`
  @media (max-width: 400px) {
    padding: 8px 10px;
  }
`;

export const CarImg = styled.div`
  user-select: none;
  cursor: pointer;
  & div {
    max-width: 300px;
    max-height: 150px;
    margin: 0 auto;
  }
  @media (min-width: 550px) {
    & div {
      max-width: 200px;
    }
  }
  & img {
    object-fit: contain !important;
  }
`;

export const Name = styled.strong`
  font-family: ${(props) => props.theme.font.primary};
  ${(props) => props.theme.font_size.small};
  margin: 0.5rem 0;
  text-transform: uppercase;
  display: block;
`;

export const Buttons = styled.div`
  button {
    width: 100%;
    display: block;
    margin-top: 0.5rem;
  }
  @media (min-width: 360px) {
    display: flex;
    justify-content: space-between;
    button {
      width: auto;
      display: unset;
    }
  }
`;

export const Spec1 = styled.div``;
export const Spec2 = styled.div``;
export const Spec3 = styled.div`
  display: grid;
  align-items: center;
`;

export const CalculatedPrices = styled.div`
  strong {
    font-weight: bold;
    display: block;
    margin-top: 1.25rem;
  }
  & p {
    font-family: ${(props) => props.theme.font.secondary};
    ${(props) => props.theme.font_size.large};
    margin: 0.5em 0;
    text-align: center;
    margin-bottom: 0;
  }

  small {
    display: block;
    ${(props) => props.theme.font_size.xxsmall};
    text-align: center;
    line-height: 1.5;
  }

  @media (max-width: 549px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    strong {
      margin-top: 0.5rem;
    }
  }
`;

export const GeneralPrices = styled.div`
  strong {
    font-weight: bold;
    display: block;
  }
  & p {
    font-family: ${(props) => props.theme.font.secondary};
    ${(props) => props.theme.font_size.xsmall};
    margin: 0;
    padding: 0;
    text-align: justify;
    & b {
      float: right;
    }
  }
`;

export const Icons = styled.div`
  display: flex;
`;

export const Fuel = styled(({ color, ...rest }) => <div {...rest} />)`
  width: 28px;
  height: 28px;
  font-size: 10px;
  display: flex;
  color: white;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  margin-right: 5px;
  background: ${(params) => `${params.color}`};
`;

export const IconParam = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  font-family: ${(props) => props.theme.font.secondary};
  font-size: 16px;
  margin-right: 5px;
  svg {
    width: 26px;
    height: 26px;
    color: #222;
    margin-right: 2px;
  }
`;

export const Wrapper = styled(
  ({ noBorder, hidePrices, noHoverEffect, ...rest }) => <figure {...rest} />
)`
  position: relative;
  cursor: pointer;
  display: grid;
  grid-template-areas:
    "spec1"
    "img"
    "spec2"
    ${(props) => (props.hidePrices ? "" : '"spec3"')}
    "bottom";
  grid-template-rows: auto 1fr auto;

  ${(props) =>
    props.noBorder
      ? ""
      : `

box-shadow: 0 0 12px 8px rgba(0, 0, 0, 0.08);
padding: 12px 16px;
border-radius: 4px;

`}

  margin: 10px auto;
  width: 100%;

  grid-gap: 0.5rem;

  ${CarImg} {
    grid-area: img;
  }

  ${Spec1} {
    grid-area: spec1;
  }

  ${Spec2} {
    grid-area: spec2;
  }

  ${Spec3} {
    grid-area: spec3;
  }

  ${Buttons} {
    grid-area: bottom;
  }

  transition: transform ease-in-out 0.25s, box-shadow ease-in-out 0.25s;

  ${(props) =>
    props.noBorder
      ? ""
      : `

  border: solid 1px #eaeaea;

`}

  @media (min-width: 550px) {
    grid-template-areas:
      "spec1 spec2 ${(props) => (props.hidePrices ? "" : "spec3")}"
      "img spec2 ${(props) => (props.hidePrices ? "" : "spec3")}"
      "bottom bottom ${(props) => (props.hidePrices ? "" : "bottom")}";
    grid-gap: 1rem;

    ${(props) =>
      props.noHoverEffect
        ? ""
        : `&:hover, &:focus, &:active {
      transform: scale(1.02);
      box-shadow: 0 0 16px 10px rgba(0, 0, 0, 0.06);
    }`}
  }
`;

export const DialogName = styled.strong`
  font-family: ${(props) => props.theme.font.primary};
  ${(props) => props.theme.font_size.large};
  margin: 1rem 0;
  display: block;
`;

export const Param = styled(({ Icon, name, children, ...props }) => {
  return (
    <div {...props}>
      {Icon ? <Icon title={name} /> : null} {children}
    </div>
  );
})`
  font-family: ${(props) => props.theme.font.secondary};
  ${(props) => props.theme.font_size.xsmall};
  line-height: 1.5;
  display: flex;
  align-items: center;
  svg {
    width: 1em;
    height: 1em;
    margin-right: 0.5em;
  }
`;

export const FullParam = styled(({ Icon, name, children, ...props }) => {
  return (
    <dl {...props}>
      <dt>{name}</dt>
      <dd>{children}</dd>
    </dl>
  );
})`
  font-family: ${(props) => props.theme.font.secondary};
  ${(props) => props.theme.font_size.xsmall};
  line-height: 1.5;
  display: flex;
  align-items: stretch;
  margin: 0;
  dt {
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    min-width: 155px;
    padding: 1px 3px;
  }
  dd {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  border-bottom: solid 1px #eaeaea;
  &:last-of-type {
    border-bottom: none;
  }
`;

export const DialogWrapper = styled.div`
  font-family: ${(props) => props.theme.font.secondary};
  ${(props) => props.theme.font_size.regular};
  color: #222;

  ${CarImg} {
    max-width: 400px;
    padding: 5%;
  }

  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2rem;
  @media (min-width: 750px) {
    grid-template-columns: 1fr 1fr;
  }
`;

export const DialogLeft = styled.div``;
export const DialogRight = styled.div`
  p {
    font-family: ${(props) => props.theme.font.secondary};
    ${(props) => props.theme.font_size.xsmall};
    & strong {
      color: #222;
    }
  }
`;
