import React, { useState } from "react";
import { Droplet } from "styled-icons/boxicons-solid";
import { Suitcase } from "styled-icons/fa-solid";
import { Gear } from "styled-icons/octicons";
import { Snowflake } from "styled-icons/fa-regular";
import { Person } from "styled-icons/octicons";
import { DoorClosed } from "styled-icons/fa-solid";
import { Calendar } from "styled-icons/boxicons-regular";
import { Meter } from "styled-icons/icomoon";
import { ReactComponent as Manual } from "@static/icons/manual.svg";
import Img from "gatsby-image";
import {
  Wrapper,
  CarImg,
  Name,
  DialogName,
  Param,
  CalculatedPrices,
  GeneralPrices,
  Spec1,
  Spec2,
  Spec3,
  Icons,
  Fuel,
  IconParam,
  DialogWrapper,
  DialogLeft,
  DialogRight,
  Buttons,
  Params,
  DialogContent,
  FullParam,
} from "./style";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { calculateRental } from "./../../../calc";
import styled from "styled-components";

const ReserveButton = styled(Button)`
  z-index: 15;
`;

const CarCard = ({
  onChoose,
  noBorder,
  hideButtons,
  hidePrices,
  noHoverEffect,
  img,
  segment,
  car,
  rentalState,
  ...rest
}) => {
  const [popup, setPopup] = useState(false);
  let dialog = null;
  const fullScreen = useMediaQuery("@media (max-width: 750px)");
  const handleClose = () => {
    setPopup(false);
  };
  const {
    name,
    trunkCapacity,
    seats,
    doors,
    engineEfficiency,
    airConditioning,
    transmission,
    description,
    engineType,
    enginePower,
    engineVolume,
    fuelType,
    productionYear,
  } = car;
  const { dayPrices } = segment;
  const calc = rentalState
    ? calculateRental(
        rentalState.odbiorDate,
        rentalState.zwrotDate,
        segment,
        car,
        null,
        null,
        []
      )
    : null;
  const cennikDobowy = (
    <>
      {calc ? (
        <CalculatedPrices>
          <div>
            <strong>Cena za dobę:</strong>
            <p>{calc.dayPrice} zł</p>
          </div>
          <div>
            <strong>Cena wynajmu:</strong>
            <p>{calc.periodPrice} zł</p>
          </div>
        </CalculatedPrices>
      ) : (
        <GeneralPrices>
          <strong>Cena za dobę:</strong>
          <p>
            <br />1 dzień <b>{dayPrices[0]} zł</b>
            <br />2 dni <b>{dayPrices[1]} zł</b>
            <br />3 - 7 dni <b>{dayPrices[2]} zł</b>
            <br />8 - 14 dni <b>{dayPrices[3]} zł</b>
            <br />
            15 - 30 dni <b>{dayPrices[4]} zł</b>
            <br />
            od 31 dni <b>{dayPrices[5]} zł</b>
          </p>
        </GeneralPrices>
      )}
    </>
  );

  if (popup) {
    dialog = (
      <Dialog
        fullScreen={fullScreen}
        open={true}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"sm"}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent>
          <DialogWrapper>
            <DialogLeft>
              <DialogName>{name}</DialogName>

              <CarImg>
                <Img fluid={img} />
              </CarImg>

              <FullParam Icon={Droplet} name="Rodzaj paliwa">
                <Fuel
                  title="Rodzaj paliwa"
                  color={
                    fuelType === "LPG"
                      ? "#49ade9"
                      : fuelType === "PB"
                      ? "#69a031"
                      : fuelType === "ON"
                      ? "#424242"
                      : "orange"
                  }
                >
                  {fuelType}
                </Fuel>
              </FullParam>
              <FullParam Icon={Droplet} name="Spalanie">
                {engineEfficiency}
              </FullParam>
              <FullParam Icon={Suitcase} name="Pojemność bagażnika">
                {trunkCapacity}
              </FullParam>

              <FullParam Icon={DoorClosed} name="Ilość drzwi">
                {doors} drzwi
              </FullParam>
              <FullParam Icon={Calendar} name="Rok produkcji">
                {productionYear}
              </FullParam>
              <FullParam Icon={Meter} name="Pojemność / moc silnika">
                {engineVolume} / {enginePower} KM
              </FullParam>
              <FullParam
                Icon={transmission.substring(0, 1) == "A" ? Gear : Manual}
                name="Skrzynia biegów"
              >
                {transmission === "manual" ? "manualna" : "automatyczna"}
              </FullParam>
              <FullParam Icon={Snowflake} name="Klimatyzacja">
                {airConditioning
                  ? airConditioning === "manual"
                    ? "manualna"
                    : "automatyczna"
                  : "brak"}
              </FullParam>
              <FullParam Icon={Person} name="Liczba miejsc">
                {seats} miejsc
              </FullParam>
            </DialogLeft>
            <DialogRight>
              <p
                style={{ marginBottom: "1rem" }}
                dangerouslySetInnerHTML={{ __html: description }}
              />
              {cennikDobowy}
            </DialogRight>
          </DialogWrapper>
        </DialogContent>
        <DialogActions>
          <Button
            size="small"
            variant="outlined"
            onClick={handleClose}
            autoFocus
          >
            Zamknij
          </Button>
          {onChoose ? (
            <Button
              size="small"
              variant="contained"
              color="secondary"
              onClick={() => {
                setPopup(false);
                onChoose(car);
              }}
            >
              Zarezerwuj
            </Button>
          ) : null}
        </DialogActions>
      </Dialog>
    );
  }
  return (
    <>
      {dialog}

      <Wrapper
        noBorder={noBorder}
        hidePrices={hidePrices}
        noHoverEffect={noHoverEffect}
        onClick={() => setPopup(true)}
        {...rest}
      >
        <Spec1>
          <Name>
            <figcaption>{name}</figcaption>
          </Name>
          <Icons>
            <Fuel
              title="Rodzaj paliwa"
              color={
                fuelType === "LPG"
                  ? "#49ade9"
                  : fuelType === "PB"
                  ? "#69a031"
                  : fuelType === "ON"
                  ? "#424242"
                  : "orange"
              }
            >
              {fuelType}
            </Fuel>
            <IconParam>
              <Person title="Liczba miejsc" /> {seats}
            </IconParam>
            <IconParam
              title={
                transmission.substring(0, 1) == "A"
                  ? "Automatyczna skrzynia biegów"
                  : "Ręczna skrzynia biegów"
              }
            >
              {transmission.substring(0, 1) == "A" ? <Gear /> : <Manual />}{" "}
              {transmission.substring(0, 1)}
            </IconParam>
            <IconParam title="Klimatyzacja">
              <Snowflake /> {airConditioning ? "A/C" : "-"}
            </IconParam>
          </Icons>
        </Spec1>
        <CarImg>
          <Img objectFit="contain" fluid={img} onClick={() => setPopup(true)} />
        </CarImg>
        <Spec2>
          <Param Icon={Droplet} name="Spalanie">
            {engineEfficiency}
          </Param>
          <Param Icon={Suitcase} name="Pojemność bagażnika">
            {trunkCapacity}
          </Param>
          <Param Icon={DoorClosed} name="Ilość drzwi">
            {doors} drzwi
          </Param>
          <Param Icon={Calendar} name="Rok produkcji">
            {productionYear}
          </Param>
          <Param Icon={Meter} name="Pojemność / moc silnika">
            {engineVolume} / {enginePower} KM
          </Param>
        </Spec2>
        {hidePrices ? null : <Spec3>{cennikDobowy}</Spec3>}
        {hideButtons ? null : (
          <Buttons>
            <Button
              size="small"
              variant="outlined"
              onClick={() => setPopup(true)}
            >
              Więcej informacji
            </Button>
            {onChoose ? (
              <ReserveButton
                size="small"
                variant="contained"
                color="secondary"
                onClick={(ev) => {
                  ev.stopPropagation();
                  onChoose(car);
                }}
              >
                Zarezerwuj
              </ReserveButton>
            ) : null}
          </Buttons>
        )}
      </Wrapper>
    </>
  );
};

export default CarCard;
